$global-config: (
  theme: (
    main: (
      colors: (
        main: #0000007f,
        primary: #53b324,
        secondary: #262626,
        ligth--secondary: #00000026,
        orange: #ffa526,
        white: white,
        gray: #545454,
        gray--medium: #dfdfdf,
        gray--light: #f2f2f2,
        gray--lightest: #f7f7f7,
        blue: #1c69d4,
        danger: #fc0d1b,
      ),
    ),
  ),
  border: (
    style: 1px solid rgba(0, 0, 0, 0.15),
    style-lighter: 1px solid #545454,
    radius: 2px,
  ),
  sizes: (
    xs: 12px,
    sm: 16px,
    md: 24px,
    lg: 36px,
  ),
);
