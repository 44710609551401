@import 'src/assets/sass/global';

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .user-circle {
    width: 48px;
    height: 48px;

    background-color: global-get(theme, main, colors, blue);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 12px;
  }

  .user-info__name {
    margin-bottom: 8px;
  }
}

.log-out,
.account-settings {
  height: max-content !important;
  display: grid !important;

  color: map-deep-get($typography-config, colors, main) !important;

  &:hover {
    color: light(map-deep-get($typography-config, colors, main)) !important;
  }
}

.user-icon {
  width: 24px;
}