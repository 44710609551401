.remove {
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      cursor: pointer !important;
    }
  }

  .ExpandedRow {
    .ant-table-cell {
      padding: 0 !important;
    }

    .checkBox {
      padding: 12px 14px 12px 12px;
      margin-left: 26px;
    }

    .row,
    .selectedRow {
      padding: 10px;
      height: 39px;
      opacity: 0.3;

      .customerId {
        width: 22.2%;
      }

      .customerName {
        width: 22.5%;
      }

      .dealer {
        width: 21.4%;
      }
    }

    .selectedRow {
      opacity: 1 !important;
    }

    .jpCustId {
      padding-left: 2px !important;
    }

    .jpCustName {
      padding-left: 2px !important;
    }

    .jpCustDealer {
      padding-left: 4px !important;
    }

    .ant-table-row-selected .ant-table-cell span {
      opacity: 0.1 !important;
    }
  }
}
