@import 'src/assets/sass/global';

.tagsContainer {
  width: 100%;
  background-color: map-deep-get($typography-config, colors, lightSecondary);
  display: flex;
  padding: 17px 16px 9px;

  .ant-tag {
    background-color: map-deep-get($typography-config, colors, lightSecondary) !important;
    border-radius: 18px;
    width: fit-content;
    height: 30px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: normal normal normal 12px/14px Arial;
    letter-spacing: 0px;
    color: map-deep-get($typography-config, colors, main) !important;
  }

  .ant-tag-close-icon {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(212, 212, 212);
    border-radius: 50%;
    margin-left: 12px;

    svg {
      fill: map-deep-get($typography-config, colors, gray);
      width: 13px;
      height: 13px;
    }
  }
}
