@each $name, $size in global-get(sizes) {
  .container-#{$name} {
    width: 100%;
    padding: $size;
  }
}

.container-full {
  width: 100%;
  padding: 0;
}
