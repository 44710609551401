.statusResults {
  width: 100%;
  column-gap: 24px;
}

@media only screen and (max-width: 1700px) {
  .statusResults {
    display: block;
  }
}

@media only screen and (min-width: 1700px) {
  .statusResults {
    display: flex;
  }
}
