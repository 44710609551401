  .progress-detailed {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    &__title {
      align-self: flex-end;
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 6px;
    }
  }