.resultsContainer {
  @media only screen and (max-width: 1700px) {
    margin-top: 25px;
  }

  .TableContainer {
    margin-top: 12px;
    border-bottom: none;
    width: 100%;

    .ant-table-thead {
      tr {
        height: 66px;
        .ant-table-cell {
          white-space: normal !important;
          text-align: left;
          font-size: 14px;

          &:nth-child(1) {
            width: fit-content;
          }
        }
      }
    }

    .ant-table-tbody {
      tr {
        .ant-table-cell {
          text-align: right;
          font-size: 14px;
          padding-right: 40px !important;

          &:nth-child(1) {
            padding-right: 15px !important;
          }

          &:nth-child(3) {
            padding-right: 15px !important;
          }
        }
      }
    }
  }
}
