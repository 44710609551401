@import 'src/assets/sass/global';

.notification {
  width: 8px;
  height: 8px;

  position: absolute;
  top: 12px;
  right: 12px;

  background-color: global-get(theme, main, colors, danger);
  border-radius: 50%;
}