@import 'src/assets/sass/global';

.parametersContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .sampleRow {
    width: 100%;
    display: flex;
    column-gap: 48px;
  }

  .sampleInput {
    width: 100%;
    margin-bottom: 24px;

    .input {
      margin-top: 12px !important;
      height: 36px;
      color: global-get(theme, main, colors, secondary);
      cursor: text;
    }

    .datePickerContainer {
      display: flex;
      justify-content: space-between;
      column-gap: 24px;
      margin-top: 16px;

      .dateItem {
        width: 100%;

        .datePicker {
          width: 100%;
          height: 36px;
          margin-top: 12px;

          .ant-picker-input {
            input {
              margin-left: 35px;
            }

            .ant-picker-suffix {
              position: absolute;
              margin: 0;
              cursor: default !important;
            }
          }
        }
      }
    }
  }

  .textArea {
    width: 100%;

    .textArea {
      margin-top: 12px;
      height: 96px;
      color: global-get(theme, main, colors, secondary);
      cursor: text;
    }
  }
}
