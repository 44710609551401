@import 'src/assets/sass/global';

.customers__customer {
  background-color: global-get(theme, main, colors, white);
  box-shadow: 0px 0px 3px #00000026;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  
  &:hover {
    background-color: darken(global-get(theme, main, colors, white), 5)
  }

  &--active-indicator {
    position: absolute;
    background-color: global-get(theme, main, colors, blue);
    width: 3px;
    left: 0;
    top: 0;
    border-radius: 2px;
    height: 100%;
  }

  .star {
    font-size: 20px;
    color: global-get(theme, main, colors, blue);
  }

  .container-sm {
    display: flex;
    column-gap: 16px;
  }

  &__info {
    width: 100%;

    .mb-xs {
      margin-bottom: 8px;
    }

    &__rows {
      display: flex;
      flex-direction: column;
      row-gap: 6px;
    }

    :last-child {
      border: none !important;

      & > div {
        padding-bottom: 0;
      }
    }
  }
}