.warning-modal {
  width: 560px !important;

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    * {
      margin: 0;
    }

    .btn {
      width: 128px;
      height: 36px;
    }
  }

  .warning-icon,
  .modal-content {
    margin-bottom: 24px;
  }

  .modal-title {
    margin-bottom: 16px;
  }

  .modal-content {
    line-height: 21px;
  }
}