.ActivityHistoryContainer {
  .TableContainer {
    margin-top: 12px;
    border-bottom: none;

    .ant-table-row :last-child {
      width: unset !important;
    }

    .expandableRow {
      .ant-table-cell {
        .expandIcon {
          margin-right: 12px !important;
        }
      }
    }
  }
}
