@import 'src/assets/sass/global';

.parts-modal-grid {
  .grid {
    display: grid;
    grid-template-columns: 36px 225px 120px 120px auto 1fr 220px;
    padding: 7px 12px;
    cursor: pointer;
    height: 40px;

    .grid-item {
      display: flex;
      justify-content: center;
      align-items: center;

      &.grid-start {
        justify-content: flex-start;
      }

      &:last-child {
        margin-top: -3px;
      }
    }

    &.uncollapsed {
      background-color: global-get(theme, main, colors, gray--lightest);
    }

    .ant-progress-inner {
      background-color: #E7E7E7;
    }
  }

  .w-60 {
    width: 60px;
  }

  .toggle-icon {
    width: 12px;
    margin-left: 6px;
    display: flex;
    align-items: center;
  }

  .progress-detailed {
    margin-bottom: 3px;
  }

  .border-bottom:last-child {
    border: none;
  }
}
