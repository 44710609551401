.vehicle-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 24px;

  @media screen and (max-width: 900px) {
    & {
      grid-template-columns: 1fr;
    }
  }
}
