.demo-btn {
  border: 1px #FFFFFF4D solid !important;
  border-radius: 16px !important;
  color: white !important;
}

.demo-btn:not([disabled]):hover {
  color: white !important;
  border-color: white !important;

  transition: color 1s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.demo-text{
  line-height: 21px !important;
  margin-bottom: 8px !important;

  &:last-child {
    margin-bottom: 0 !important;
  }
}
