body {
  margin: 0;
  font-family: 'Arial' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  max-height: 100vh;
  overflow-x: hidden;
  min-width: 924px;
}
