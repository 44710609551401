@import 'src/assets/sass/global';

.probability-card-stats {
  display: grid;
  grid-template-columns: 3fr 4fr 78px;
  cursor: pointer;

  span {
    line-height: 16px;
  }

  &__progress {
    display: flex;
    justify-content: flex-end;

    &>div {
      width: 78.7px;

      &>div {
        width: 60px;
      }
    }
  }
}

.view-more {
  &:hover {
    div {
      color: #40a9ff;
    }
  }

  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:focus-within {
    border-top: global-get(border, style) !important;
  }
}