.ant-card-body {
  padding: 0;
}

.ant-progress-success-bg,
.ant-progress-bg,
.ant-progress-status-success .ant-progress-bg {
  background-color: global-get(theme, main, colors, blue);
}

.ant-progress-outer {
  .ant-progress-inner {
    border: none;
  }
}

.ant-progress-inner,
.ant-progress-bg {
  border-radius: 0;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: global-get(theme, main, colors, secondary);
}

.ant-menu {
  display: flex;
  align-items: center;
  gap: 32px;

  background-color: global-get(theme, main, colors, secondary) !important;
  height: 100%;

  .ant-menu-item {
    padding: 0;
  }
}

.ant-select,
.ant-select-arrow {
  color: white;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 24px;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  margin-right: 0px;
}

.ant-card-bordered {
  border: none;
}

body .ant-menu-dark .ant-menu-item-disabled,
body .ant-menu-dark .ant-menu-submenu-disabled,
body .ant-menu-dark .ant-menu-item-disabled > a,
body .ant-menu-dark .ant-menu-submenu-disabled > a,
body .ant-menu-dark .ant-menu-item-disabled > span > a,
body .ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba($color: white, $alpha: 0.65) !important;
  opacity: 1;
}

.ant-progress,
.ant-progress-outer {
  display: flex;
  align-items: center;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0;
}

.ant-select-arrow {
  right: 0;
}

.ant-select {
  padding-left: 12px;
}

.ant-select-item-option {
  cursor: pointer !important;
}

.ant-form-item-label > label.ant-form-item-required::before {
  content: '';
  margin-right: 0;
}

.ant-radio-button-wrapper {
  background-color: global-get(theme, main, colors, gray--lightest) !important;
  border-color: #d9d9d9;
  min-width: 120px;
  text-align: center;

  &:hover {
    color: unset;
  }

  &:not(:hover) {
    img {
      opacity: 0.5;
    }

    color: rgba($color: global-get(theme, main, colors, secondary), $alpha: 0.5) !important;
  }

  &.ant-radio-button-wrapper-checked {
    background-color: white !important;

    img {
      opacity: 1;
    }

    color: global-get(theme, main, colors, blue) !important;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none !important;
}

.ant-modal-wrap {
  overflow: overlay;
}

.ant-input-affix-wrapper .ant-input-prefix .anticon.anticon-search {
  color: global-get(theme, main, colors, main);
}

.ant-input-prefix {
  margin: 0 8px;
}

.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background: global-get(theme, main, colors, blue) !important;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: global-get(theme, main, colors, blue) !important;
}

.ant-picker-today-btn {
  color: global-get(theme, main, colors, blue) !important;
}

.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}

.anticon-loading {
  color: global-get(theme, main, colors, blue) !important;
}
