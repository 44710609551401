@import 'src/assets/sass/global';

.filters-container {
  display: flex;
  align-items: center;
  column-gap: 8px;

  .ant-input-affix-wrapper {
    background-color: global-get(theme, main, colors, gray--light);
    border: none;
    height: 36px;
    padding: 6px;

    input {
      background-color: global-get(theme, main, colors, gray--light);
      text-indent: 8px;
      color: map-deep-get($typography-config, colors, secondary);

      &::-webkit-input-placeholder {
        /* Edge */
        color: map-deep-get($typography-config, colors, secondary);
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: map-deep-get($typography-config, colors, secondary);
      }

      &::placeholder {
        color: map-deep-get($typography-config, colors, secondary);
      }
    }
  }

  .filter-popup {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .ant-select {
      padding: 0;
      width: 250px;
      color: map-deep-get($typography-config, colors, main);
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      margin-left: 10px;
      color: map-deep-get($typography-config, colors, main);
    }

    .ant-select-arrow {
      right: 10px;
      color: map-deep-get($typography-config, colors, main);
    }
  }

  .p-sm {
    padding: 11px;
  }

  .ant-input-prefix {
    font-size: 18px;
  }
}