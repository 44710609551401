@import 'src/assets/sass/global';

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: global-get(theme, main, colors, blue) !important;
  border-color: #d9d9d9 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: unset !important;
}

.ant-radio-button,
.ant-radio-button-wrapper {
  font-size: global-get(sizes, xs) !important;
  font-weight: bold !important;
  line-height: 14px;
}

.ant-radio-button-wrapper {
  padding: 12px !important;
  height: unset !important;
  line-height: 10px !important;
  background-color: white !important;
}

.ant-radio-button-wrapper-disabled {
  color: rgba($color: global-get(theme, main, colors, secondary), $alpha: 0.5) !important;
}