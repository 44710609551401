$typography-config: (
  sizes: (
    xs: 10px,
    sm: 12px,
    md: 14px,
    lg: 16px,
    xxs: 20px,
  ),
  colors: (
    main: #262626,
    secondary: #0000007f,
    lightSecondary: #0000000d,    
    primary: #53b324,
    darkPrimary: #53b3241a,
    orange: #ffa526,
    darkOrange: #ffb7261a,
    white: white,
    gray: rgba(255, 255, 255, 0.6),
    darkGray: #464646,
    blue: #1c69d4,
    darkBlue: #1c69d41a,
    danger: #ff4d4f,
  ),
  weight: (
    regular: 400,
    bold: 700,
  ),
);
