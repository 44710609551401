.btn {
  @each $name, $color in global-get(theme, main, colors) {
    &.btn-#{$name} {
      color: white;
      background-color: $color;

      &:hover {
        background-color: light($color);
        border: 1px $color solid !important;
      }
    }
  }
}