@import 'src/assets/sass/global';

.ant-modal-wrap.ant-modal-centered {
  overflow-y: hidden;
}

.ant-modal-centered::before {
  display: none !important;
}

.editModal {
  width: 70% !important;
  padding: 24px;
  min-width: 1024px;
  max-width: 1600px;

  .ant-modal-header {
    padding: 21px !important;

    .ant-modal-title {
      font-size: 20px;
      text-transform: uppercase;
      color: global-get(theme, main, colors, secondary);
      font-weight: bold;
    }
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-modal-body {
      height: 80vh;
      padding: 0 !important;
      display: flex;
      flex-direction: column;

      @media screen and (max-height: 900px) {
        height: 70vh;
      }

      @media screen and (max-height: 500px) {
        height: 50vh;
      }
    }
  }

  .border-default.border-top.footer {
    height: 84px;
    background: global-get(theme, main, colors, white) !important;
    z-index: 1000;
  }

  .modalContent {
    display: flex;
    height: 100%;

    .sidebar {
      width: 25%;
      min-width: 256px;
      display: flex;
      flex-direction: column;

      .filterContain {
        padding: 10px 8px;
      }
    }

    .ant-collapse.ant-collapse-icon-position-right {
      height: 100%;
      overflow-y: scroll !important;

      .ant-collapse-content-box {
        padding: 0;
      }

      .ant-collapse-header {
        height: 40px;
        font-weight: bold;
        text-transform: uppercase;
        color: global-get(theme, main, colors, secondary);
        background-color: global-get(theme, main, colors, gray--light);
        padding: 12px !important;
        font-size: 14px;
      }

      .disabled {
        cursor: not-allowed !important;
        .expandIcon {
          img {
            cursor: not-allowed !important;
          }
        }
      }

      .enable {
        cursor: pointer !important;
      }

      .expandIcon {
        height: 40px;
        font-size: 14px;
        color: global-get(theme, main, colors, secondary);
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .starFilled {
          margin-right: 10px;
          font-size: 20px;
          cursor: pointer;
        }

        .starOutlined {
          margin-right: 10px;
          font-size: 20px;
          cursor: pointer;
        }

        .counts {
          font-size: 14px;
          color: global-get(theme, main, colors, blue);
          margin-right: 3px;
        }
      }
    }

    .border-default.TableContainer {
      height: 100%;
      overflow-y: scroll !important;
    }

    .mainContent {
      width: 100%;
      display: flex;
      flex-direction: column;

      .border-default.TitleContainer {
        border: none !important;
      }
      .pagination {
        display: none;
      }

      .ant-table-cell.ant-table-selection-column {
        padding: 12px 0 12px 15px;
      }

      .ant-table-cell.ant-table-selection-column {
        width: fit-content !important;
      }

      .ant-table-row :last-child {
        width: 18px;
        height: 18px;
      }

      .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        display: none;
      }

      .ant-checkbox * {
        width: 18px;
        height: 18px;

        .ant-checkbox-inner {
          width: 18px;
          height: 18px;
        }
      }
    }

    .input {
      height: 36px;
      border: none;
      background-color: global-get(theme, main, colors, gray--light);

      .ant-input-prefix {
        margin: 0;
        margin-right: 8px;

        span {
          font-size: 20px;
        }
      }

      .ant-input {
        font: normal normal normal 14px/16px Arial;
        background-color: global-get(theme, main, colors, gray--light) !important;

        &::placeholder {
          color: map-deep-get($typography-config, colors, secondary);
        }
      }
    }
  }
  .footer {
    height: 84px;
    padding: 24px;

    Button {
      width: 96px;
      height: 36px;
    }

    .cancelBtn {
      margin-left: 16px;

      &.btn.btn-white:hover {
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
      }
    }
  }

  .inActiveClass {
    background-color: #00000019 !important;

    span {
      color: global-get(theme, main, colors, main) !important;
    }
  }

  .activeClass {
    span {
      color: global-get(theme, main, colors, blue) !important;
    }
  }

  .ant-checkbox-input {
    opacity: 0.7;
  }

  .ant-table-row td {
    opacity: 0.5;
  }

  .ant-table-cell.ant-table-selection-column {
    opacity: 1;
  }

  .ant-table-tbody tr.ant-table-row-selected td {
    background-color: global-get(theme, main, colors, white) !important;
    opacity: 1;
  }
}
