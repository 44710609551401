.teleservice-item {
  display: flex;
  flex-direction: column;

  gap: 8px;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
  }

  .text-sm {
    line-height: 12px;
  }

  &.disabled {
    opacity: 0.15;

    span {
      color: black;
    }
  }
}