@import 'src/assets/sass/global';

.selectNewSC {
  &.ant-select.ant-select-single.ant-select-show-arrow {
    padding: 0;
    width: 192px !important;
    color: map-deep-get($typography-config, colors, main) !important;
    border: 1px solid global-get(theme, main, colors, ligth--secondary) !important;
    height: 26px;

    .ant-select-selector {
      display: flex;
      align-items: center;
      padding-left: 12px !important;
      height: 24px;

      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }

      .ant-select-selection-placeholder {
        color: map-deep-get($typography-config, colors, main) !important;
        opacity: 1 !important;
        font: normal normal normal 14px/16px Arial !important;
      }
    }

    .ant-select-arrow {
      margin-right: 8px !important;
      top: 40%;
      color: map-deep-get($typography-config, colors, main) !important;
    }
  }
}
