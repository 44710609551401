@import 'src/assets/sass/global';

.customers-contracts {
  min-width: 414px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .circle {
    background-color: rgba(global-get(theme, main, colors, gray), 0.2);
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-icon {
    font-size: 32px;
    color: global-get(theme, main, colors, blue) !important;
  }

  @media screen and (max-width: 1024px) {
    height: 500px;
  }
}
