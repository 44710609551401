@import 'src/assets/sass/global';

.ant-btn.downloadBtn.btn.btn-white {
  height: 36px;
  padding: 11px 12px 11px 8px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  &:hover {
    border: 1px solid global-get(theme, main, colors, ligth--secondary) !important;
  }
}
