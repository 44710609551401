@import 'src/assets/sass/global';

.login {
  width: 100%;
  height: 100%;

  &-bg {
    width: inherit;
    height: inherit;
    background-color: global-get(theme, main, colors, gray--light);
  }

  &__card {
    height: fit-content;
    width: 482px !important;
  }

  &__form-container{ 
    display: flex;
    flex-direction: column;
    row-gap: 36px;
    
    &__form {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }
  }

  &-title {
    line-height: 23px !important;
    margin: 0;
  }

  .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    font-size: map-deep-get($typography-config, sizes, md);
    line-height: map-deep-get($typography-config, sizes, lg);
    padding-bottom: 12px;
    color: black;
  }

  .ant-form-item-label > label {
    height: unset;
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-input-lg,
  .ant-btn-lg {
    height: 48px;
  }

  .h-16 {
    height: 16px;
  }
}