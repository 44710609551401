.parts-modal {
  width: 1000px !important;

  .ant-modal-title {
    display: flex;
    align-items: center;
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-modal-header {
    padding: 14px 24px;
  }
}