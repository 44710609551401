@import 'src/assets/sass/global';

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: global-get(theme, main, colors, white);
  white-space: nowrap !important;

  .title {
    display: flex;

    .arrowLeft {
      font-size: 20px;
      color: #262626;
      margin: 0 20px 0 4px;
    }

    .ant-tag {
      margin: 0 12px;
    }
  }
}
