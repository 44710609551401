@import 'src/assets/sass/global';

.targetSegmentContainer {
  .targetSegmentContent {
    padding: 26px 16px;
    font-size: 14px;
    margin-top: 16px;

    .contain {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 15px;

      .ant-tag {
        background-color: map-deep-get($typography-config, colors, darkBlue);
        border-radius: 18px;
        width: fit-content;
        height: 30px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: map-deep-get($typography-config, colors, blue);
          padding: 0 3px;
        }
      }

      .keyValue:nth-child(even) {
        .keyInJpn {
          width: 18% !important;
        }
      }

      .keyValue:nth-child(odd) {
        .keyInJpn {
          width: 7% !important;
        }
      }

      .keyValue:nth-child(even) {
        .keyInEng {
          width: 10% !important;
        }
      }

      .keyValue:nth-child(odd) {
        .keyInEng {
          width: 11% !important;
        }
      }

      @media only screen and (max-width: 1700px) {
        .keyValue:nth-child(even) {
          .keyInEng {
            padding-right: 90px !important;
          }
        }
      }

      @media only screen and (max-width: 1700px) {
        .keyValue:nth-child(odd) {
          .keyInEng {
            padding-right: 100px !important;
          }
        }
      }

      @media only screen and (max-width: 1700px) {
        .keyValue:nth-child(even) {
          .keyInJpn {
            padding-right: 160px !important;
          }
        }
      }

      @media only screen and (max-width: 1700px) {
        .keyValue:nth-child(odd) {
          .keyInJpn {
            padding-right: 60px !important;
          }
        }
      }

      .keyValue {
        white-space: nowrap;
        display: flex;
        align-items: baseline;

        .keyInEng,
        .keyInJpn {
          margin-right: 30px;
        }

        .flex {
          width: 70% !important;
          flex-wrap: wrap;
          row-gap: 10px;
        }
      }
    }
  }
}
