.custom-loading {
  align-self: center;
  justify-self: center;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  
  span {
    font-size: 60px !important;
  }
}