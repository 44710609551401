@import 'src/assets/sass/global';

.finishContain {
  max-width: 900px !important;
  width: 70% !important;
  margin: 24px auto 24px;
  background-color: global-get(theme, main, colors, #ffffff);
  border: 2px solid rgba(0, 0, 0, 0.075) !important;

  .TSContainer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 20px;

    .titles {
      color: global-get(theme, main, colors, main);
      font: normal normal normal 14px/16px Arial;
    }

    .itemContent {
      padding: 20px 15px;
      display: flex;
      column-gap: 40px;

      .contain {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        width: 100%;

        .keyValue {
          display: flex;
          column-gap: 20px;
          line-break: anywhere;

          .key {
            width: 30%;
            min-width: 30%;
          }

          .flex {
            flex-wrap: wrap;
            max-width: calc(70% - 20px);
            row-gap: 10px;
          }

          .ant-tag {
            background-color: map-deep-get($typography-config, colors, darkBlue);
            border-radius: 18px;
            width: fit-content;
            height: 30px;
            margin-right: 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: map-deep-get($typography-config, colors, blue);
              padding: 0 3px;
            }
          }
        }
      }

      &.campaign-details {
        .contain {
          row-gap: 0;
        }
      }
    }
  }

  .footerContainer {
    padding: 24px;
    display: flex;
    justify-content: center;
    column-gap: 20px;

    Button {
      width: 150px;
      height: 36px;
      border-radius: 2px;
    }
  }
}
