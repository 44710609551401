@import 'src/assets/sass/global';

.detailsContainer {
  background-color: global-get(theme, main, colors, white);

  .content-overflow {
    height: calc(100vh - 185px);
    overflow-y: auto;
  }
  
  table {
    tr {
      cursor: default !important;
      word-break: keep-all;
    }

    .expandIcon {
      cursor: pointer;
    }
  }

  .customers {
    max-height: calc(100vh - 182px) !important;
  }
  .menuBorder {
    margin: 0;
    background-color: global-get(theme, main, colors, white);
    .menuSteps {
      height: 48px;
      display: flex;
      margin-left: 16px;
      text-transform: uppercase;

      div {
        padding: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .activePage {
        color: global-get(theme, main, colors, secondary);
        font-weight: bold;
        border-bottom: 3px solid global-get(theme, main, colors, blue);
      }
    }
  }
  .ant-tag {
    width: fit-content;
    height: fit-content;
    color: map-deep-get($typography-config, colors, orange);
    background-color: map-deep-get($typography-config, colors, darkOrange);
  }

  .sections-layout__sections {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
}
