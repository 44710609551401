@import 'src/assets/sass/global';

.TitleContainer {
  padding: 10px 8px;
  border-bottom: none !important;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .filters {
    display: inline-flex;
  }

  .filtersContainer {
    width: 100% !important;
    .ant-input-affix-wrapper {
      width: 100%;
    }

    .p-sm.w-inherit {
      display: flex;
      align-items: center;
    }
  }

  .btnsContainer {
    height: 36px;
    margin: 0 16px;
    display: flex;

    Button:last-child {
      border-left: none;
    }

    .inActiveClass {
      background-color: global-get(theme, main, colors, gray--lightest) !important;
      border-radius: 0;
      span {
        color: global-get(theme, main, colors, main) !important;
      }
    }

    .activeClass {
      border-radius: 0;
      background-color: global-get(theme, main, colors, white) !important;
      span {
        color: global-get(theme, main, colors, blue) !important;
      }
    }

    Button {
      height: 36px;
      padding: 4px 12px;

      &.btn.btn-white:hover {
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
      }
    }
  }
  .filters {
    .ant-select {
      padding: 0;
      color: map-deep-get($typography-config, colors, main) !important;

      .ant-select-selector {
        display: flex;
        align-items: center;
        padding-left: 12px !important;
        background-color: map-deep-get($typography-config, colors, white) !important;

        width: 128px;
        height: 36px;

        .ant-select-selection-placeholder {
          color: map-deep-get($typography-config, colors, main) !important;
          opacity: 1 !important;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 12px;
        }
      }

      .ant-select-arrow {
        width: fit-content;
        margin-right: 8px !important;
        top: 30%;
        color: map-deep-get($typography-config, colors, main) !important;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.tableContain {
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      cursor: pointer !important;
    }
  }

  .expandIcon {
    width: 23px !important;
    height: 23px !important;
    cursor: pointer;
  }

  .ant-checkbox-input {
    opacity: 0.7;
  }

  .border-default.TableContainer {
    border: none !important;
  }

  .ant-table-row.ant-table-row-level-0 {
    .ant-table-cell .anticon {
      opacity: 0.3;
    }
  }

  .ant-table-row.ant-table-row-level-0.ant-table-row-selected {
    .ant-table-cell {
      span {
        opacity: 1 !important;
      }
    }
  }

  .ant-table-cell.ant-table-selection-column,
  .ant-table-cell.ant-table-row-expand-icon-cell {
    opacity: 1;
  }

  .ant-table-tbody tr.ant-table-row-selected td {
    background-color: global-get(theme, main, colors, white) !important;
    opacity: 1;
  }

  .ant-table-cell.ant-table-row-expand-icon-cell {
    width: fit-content !important;
    padding: 9px 3px 9px 12px !important;

    span {
      width: 18px !important;
    }
  }

  .ant-table-cell.ant-table-selection-column {
    width: fit-content !important;
  }

  .ant-table-row :last-child {
    width: 18px;
    height: 18px;
  }

  .ant-checkbox {
    width: 18px;
    height: 18px;
  }

  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }

  .TableContainer .cols-2 .ant-table-thead tr th {
    width: fit-content !important;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    display: none;
  }

  .ant-table-expanded-row.ant-table-expanded-row-level-1 {
    .ant-table-cell {
      background-color: global-get(theme, main, colors, gray--lightest);
    }
  }
  .ant-table-selection-column {
    padding: 6px 3px 6px 12px !important;

    .ant-checkbox * {
      width: 18px;
      height: 18px;
    }
  }
}

.expandedRowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .expandedRow {
    padding: 3px 0 24px 84px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;

    .expandedRowItem {
      width: 100%;
      padding: 11px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;
    }
  }

  .viewBtn {
    width: fit-content;
    margin-left: 35px;
    border: none;
    color: map-deep-get($typography-config, colors, blue) !important;
    background-color: global-get(theme, main, colors, gray--lightest);
    margin-bottom: 5px;
    font-size: 12px !important;
  }
}

.contactList {
  border: 0 !important;

  .TSContainer {
    padding: 0;
    border: 0;
  }

  .footerContainer {
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-top: none !important;
  }
}

.ant-table-cell {
  height: 43px !important;
}
