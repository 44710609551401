@import 'src/assets/sass/global';

.parts-modal-details {
  padding-left: 48px;
  padding-right: 48px;

  display: grid;
  gap: 24px;

  background-color: global-get(theme, main, colors, gray--lightest);

  .row {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
  }

  img.chart {
    width: 100%;
    height: 105px;
  }

  .container-md {
    padding: 16px;
  }

  .pd-sm.container-xs {
    padding: 8px;
    padding-bottom: 0;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
}