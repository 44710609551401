@import 'src/assets/sass/global';

.tagExpand {
  width: 24px !important;
  height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;

  svg {
    width: 12px;
    fill: global-get(theme, main, colors, blue);
  }
}

.container-md.sections-header {
    padding: 0 16px;

    .right-header {
      padding: 0;
      height: 60px;

      .customer-details__name-container {
        margin: 0;

        .ant-tag {
          margin-right: 4px;
        }
      }
    }
  }
