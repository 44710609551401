@import 'src/assets/sass/global';

.TableContainer {
  height: fit-content;
  border-color: rgba(0, 0, 0, 0.075) !important;

  .table {
    border-collapse: collapse !important;
    .ant-table-thead {
      .ant-table-cell {
        margin: 0;
        padding: 12px;
        color: global-get(theme, main, colors, main);
        text-transform: uppercase;
        background-color: global-get(theme, main, colors, white);
        font: normal normal normal 12px/14px Arial;
        white-space: nowrap;
        border-color: rgba(0, 0, 0, 0.15) !important;
      }
    }

    &.cols-0 {
      td:nth-child(2) {
        width: 100% !important;
      }
    }

    .ant-table-tbody {
      tr {
        cursor: pointer;
      }
      .ant-table-cell {
        margin: 0;
        padding: 12px;
        color: global-get(theme, main, colors, secondary);
        font: normal normal normal 14px/16px Arial;
        white-space: nowrap;
        border-color: rgba(0, 0, 0, 0.15) !important;
      }
    }
  }

  .ant-table-row :last-child {
    width: 1px;
    .ant-tag {
      width: fit-content;

      &:last-child {
        margin: 0;
      }
    }
  }

  .ant-pagination-item {
    display: none;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border: none;
      cursor: pointer !important;
      background-color: global-get(theme, main, colors, white) !important;
      span {
        color: global-get(theme, main, colors, secondary);
      }
    }
  }

  .ant-table-pagination-right {
    width: 100%;

    .ant-pagination-prev {
      position: absolute;
      right: 22px;
      width: fit-content;
      top: -12px;
      z-index: 100;
    }

    .ant-pagination-next {
      position: absolute;
      right: 13px;
      margin-right: -10px;
      width: fit-content;
      top: -12px;
      z-index: 100;
    }
  }

  .ant-select-selector {
    border: none !important;
    background-color: global-get(theme, main, colors, white) !important;
  }

  .ant-pagination-options {
    position: absolute;

    span {
      color: global-get(theme, main, colors, secondary);
    }
  }

  .ant-table-pagination {
    position: absolute;
  }

  .ant-pagination-jump-next {
    display: none;
  }

  .pagination {
    padding: 13px;
    background-color: global-get(theme, main, colors, white);
    height: 41px;
    display: flex;
    position: relative;

    .showOnPageContainer {
      cursor: pointer !important;
    }

    .showingCount {
      position: absolute;
      right: 65px;
    }

    .currentPage {
      white-space: nowrap;
    }

    .ant-select-selector {
      padding-left: 4px !important;
      color: global-get(theme, main, colors, secondary);
    }

    .ant-select-arrow {
      width: fit-content;
      margin-top: -11px;

      img {
        width: 20px;
      }
      span {
        color: global-get(theme, main, colors, main);
      }
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      padding-right: 20px !important;
      color: global-get(theme, main, colors, secondary);
    }

    .ant-select-selection-placeholder {
      opacity: 1;
    }

    .ant-select-single {
      position: absolute;
      top: 5px;
      cursor: pointer;
    }

    .ant-select {
      padding: 0;
      margin-left: 8px;
      width: 40px;
    }
  }

  .ant-table-content table {
    border-collapse: collapse;
  }

  @for $cols from 1 to 10 {
    .cols-#{$cols} .ant-table-thead tr th {
      width: calc(100% / #{$cols});
    }
  }

  .ant-table-thead tr {
    &:last-child {
      width: unset;
    }
  }

  @media screen and (max-width: 1440px) {
    .ant-table-pagination-right {
      .ant-pagination-prev {
        right: 4%;
      }

      .ant-pagination-next {
        right: 13px;
      }
    }

    .pagination {
      .showingCount {
        position: absolute;
        right: 90px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .pagination {
      .showingCount {
        position: absolute;
        right: 70px;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .ant-table-pagination-right {
      .ant-pagination-prev {
        right: 3%;
      }
    }
  }
}

.rowExpand {
  background-color: global-get(theme, main, colors, gray--lightest) !important;
}

.ant-table-row.ant-table-row-level-0.ant-table-row-selected.rowExpand {
  td {
    background-color: global-get(theme, main, colors, gray--lightest) !important;
  }
}
