.flex {
  display: flex;

  &.align-center {
    align-items: center;
  }

  &.cell {
    flex: 1;
  }

  &.equal-sizing {
    >* {
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  &.flex-column {
    flex-direction: column;

    &.top {
      align-content: flex-start;
    }

    &.bottom {
      align-content: flex-end;
    }

    &.vertical-center {
      align-content: center;
    }

    &.space-between {
      align-content: space-between;
    }

    &.space-around {
      align-content: space-around;
    }
  }

  &.flex-row {
    flex-direction: row;

    &.wrap {
      flex-flow: row wrap;
    }

    &.top {
      align-items: flex-start;
    }

    &.bottom {
      align-items: flex-end;
    }

    &.vertical-center {
      align-items: center;
    }

    &.baseline {
      align-items: baseline;
    }

    &.stretch {
      align-items: stretch;
    }
  }

  &.start {
    justify-content: flex-start;
  }

  &.end {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.space-evenly {
    justify-content: space-evenly;
  }
}