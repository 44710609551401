.customers {
  display: flex;
  background-color: white;
  max-height: calc(100vh - 64px);
  height: inherit;

  @media screen and (max-width: 1024px) {
    display: block;
    flex-direction: column;
  }
}