.AftersalesContainer {
  width: 100%;

  .TableContainer {
    margin-top: 12px;
    border-bottom: none;

    .ant-table-content {
      table {
        table-layout: fixed !important;
      }
    }
  }
}
