@each $name in $percents {
  .w-#{$name} {
    width: percentage($name/100);
  }

  .h-#{$name} {
    height: percentage($name/100);
  }
}

.w-inherit {
  width: inherit;
}

.w-fit {
  width: fit-content;
}

.h-inherit {
  height: inherit;
}
