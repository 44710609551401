.progress-card-spent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 13px;
  gap: 18px;

  &:last-child {
    margin: 0;
  }
}