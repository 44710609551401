@import 'src/assets/sass/global';

.VehicleContainer {
  .TableContainer {
    margin-top: 12px;
    border-bottom: none;

    .ant-table-tbody {
      .ant-table-cell {
        img {
          width: 25px;
          margin-right: 5px;
        }
      }

      .paymentContract {
        .ant-table-cell {
          &:nth-child(3) {
            color: global-get(theme, main, colors, blue);
          }
        }
      }
    }
  }
}
