$sizes: global-get(sizes);

@each $name,
$size in $sizes {
  .p-#{$name} {
    padding: $size;
  }

  .m-#{$name} {
    margin: $size;
  }

  @each $pos-name in $positions {
    .p#{str-slice($pos-name, 0, 1)}-#{$name} {
      padding-#{$pos-name}: $size;
    }

    .p#{str-slice($pos-name, 0, 1)}-0 {
      padding-#{$pos-name}: 0;
    }

    .m#{str-slice($pos-name, 0, 1)}-#{$name} {
      margin-#{$pos-name}: $size;
    }

    .m#{str-slice($pos-name, 0, 1)}-0 {
      margin-#{$pos-name}: 0;
    }
  }
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.ml-auto {
  margin-left: auto;
}
