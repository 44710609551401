@import 'src/assets/sass/global';

.stepContainer {
  width: fit-content;
  padding: 15px 8px 13px;
  border-bottom: 3px solid global-get(theme, main, colors, white) !important;
  cursor: pointer;

  .number {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: global-get(theme, main, colors, ligth--secondary);
  }

  span {
    color: global-get(theme, main, colors, main);
    font: normal normal normal 14px/16px Arial;
  }
}

.stepContainer.active {
  border-bottom: 3px solid global-get(theme, main, colors, blue) !important;

  .number {
    background-color: global-get(theme, main, colors, blue) !important;
  }
  span {
    color: global-get(theme, main, colors, secondary) !important;
  }
}
