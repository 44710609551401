@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function global-get($keys...) {
  @return map-deep-get($global-config, $keys...);
}

@function light($color) {
  @return lighten($color, 10);
}

@function dark($color) {
  @return darken($color, 10);
}
