.popup-container {
  margin: 0;
  padding: 0;
  right: 0;
  top: 45px;
  z-index: 1050;
  position: absolute;
  box-sizing: border-box;
  padding: 4px 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  display: none;

  &[data-visible="true"] {
    display: block;
  }
}