@import 'src/assets/sass/global';

.grey-circle{
  width: 64px;
  height: 64px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: map-deep-get($typography-config, colors, blue);

  border-radius: 50%;
  background-color: global-get(theme, main, colors, gray--lightest);
}