@import 'src/assets/sass/global';

.statusContainer {
  .TableContainer {
    margin-top: 12px;
    border-bottom: none;
    width: 100%;

    .ant-table-thead {
      tr {
        height: 66px;

        .ant-table-cell {
          text-align: right;
          &:nth-child(1) {
            width: fit-content;
          }
        }
      }
    }

    .ant-table-tbody {
      tr .ant-table-cell {
        text-align: right;
        font-size: 14px;
        color: global-get(theme, main, colors, blue) !important;
      }
    }
  }
}

@media screen and (max-width: 1860px) {
  .ant-table-thead {
    tr {
      height: 80px !important;
    }
  }
}
