.loading-hoc {
  position: relative;
  height: 100%;
  width: 100%;
  opacity: 1;

  .ant-spin {
    height: 64px;
    width: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    right: 0;
    bottom: 0;
    opacity: 1;
    z-index: 1050;
  }

  .loading-container {
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
    background-color: grey;
  }
}