@import 'src/assets/sass/global';

.avg-indicator {
  width: 3px;
  height: 14px;
  background-color: global-get(theme, main, colors, orange);
  position: absolute;
  top: 17px;
  z-index: 20;
}
