@import 'src/assets/sass/global';

.fullContainer {
  width: 70% !important;
  min-width: 1024px !important;
  max-width: 1600px !important;
  margin: 24px auto 24px;
  background-color: global-get(theme, main, colors, #ffffff);
  border: 2px solid rgba(0, 0, 0, 0.075) !important;

  .TSContainer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footerContainer {
    padding: 24px;
    display: flex;
    justify-content: center;

    .backBtn {
      height: 36px;
      border-radius: 2px;
      margin-right: 16px;

      &.btn.btn-white:hover {
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
      }
    }

    .continueBtn {
      height: 36px;
      border-radius: 2px;
    }
  }
}
