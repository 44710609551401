@import 'src/assets/sass/global';

.progress-bar-bold {
  .ant-progress-bg {
    height: 20px !important;
    background-color: global-get(theme, main, colors, primary);
  }

  &.medium {
    .ant-progress-bg {
      background-color: global-get(theme, main, colors, orange);
    }
  }

  &.low {
    .ant-progress-bg {
      background-color: global-get(theme, main, colors, danger);
    }
  }
}