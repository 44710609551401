.personalInfoContainer {
  .personalInfoContent {
    padding: 12px;
    font-size: 14px;
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;

    .left {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      & > div {
        display: flex;

        .key.text-secondary {
          min-width: 90px;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      & > div {
        display: flex;

        .key.text-secondary {
          width: 150px;
          margin-right: 20px;
        }
      }
    }
  }
}
