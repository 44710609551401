@import 'src/assets/sass/global';

.stepsContainer {
  background-color: global-get(theme, main, colors, white);
  display: flex;
  justify-content: center;
  align-items: center;

  .content-overflow {
    height: calc(100vh - 185px);
    overflow-y: auto;
  }

  .dash {
    width: 16px;
    height: 5px;
    margin: 0 8px;
    border-top: 1px solid global-get(theme, main, colors, ligth--secondary);
  }

  .thirdStepNumber {
    padding-right: 1px;
  }

  .fourthStepNumber {
    padding-right: 1px;
  }
}

.countContainer {
  position: relative;
  display: flex;
  justify-content: center;

  .count {
    margin-left: 22px;
    width: fit-content;
    height: 16px;
    padding-left: 8px;
    padding-right: 8px;
    min-width: 32px;
    border-radius: 12px;
    position: absolute;
    background-color: map-deep-get($typography-config, colors, blue);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    color: map-deep-get($typography-config, colors, white);
    transform: translate(0, -50%);
  }
}
